import api from "@api";
import { getSearchResultOnRow } from "@Ekcore/util";

export default {
    state: {
        branchList: [],
        isDialogOpen: false,
        branchDto: {
            name: "",
            facultyId: "",
            universityId: "",
            yearCount: 0
        },
        columns: [
            {
                label: " اسم الفرع ",
                field: "name"
            },
            {
                label: " اسم الكلية ",
                field: "facultyId"
            },
            {
                label: "  عدد السنوات ",
                type: "number",
                field: "yearCount"
            },
            {
                label: "عدد الطلاب المسجلين ",
                type: "number",
                field: "studentsCount"
            },
            {
                label: "تاريخ الإضافة",
                sortable: true,
                field: "dateCreated"
            },
            {
                label: "التفاصيل",
                field: "details",
                sortable: false
            }
        ],
    },
    getters: {
        branches({ branchList }, getter, { filter }) {
            return branchList.filter(el => {
                return getSearchResultOnRow(filter, el);
            });
        }
    },
    mutations: {
        Get_Branch_List(state, payload) {
            state.branchList = payload ;
        },
        Add_Branch(state, payload) {
            state.branchList.unshift(payload);
        },
        Update_Branch(state, payload) {
            let dto = state.branchList.find(ele => ele.id == payload.id);
            Object.assign(dto, payload);
        },
        IS_DIALOG_OPEN(state, payload) {
            state.isDialogOpen = payload;
        },
        SET_BRANCH_DTO(state, payload) {
            if (payload) state.branchDto = { ...payload };
            else
                state.branchDto = {
                    name: "",
                    facultyId: "",
                    yearCount: 0
                };
        },
        Delete_Brancheis(state, payload) {
            state.branchList = state.branchList.filter(
                el => !payload.includes(el.id)
            );
        },
    },
    actions: {
        getBranchList({ commit }) {
            api.get("Department/Dash/GetAll", ({ data }) => {
                commit("Get_Branch_List", data);
            });
        },
        addBranch({ commit, dispatch }, payload) {
            api.post(
                "Department/Dash/Add",
                payload,
                ({ data }) => {
                    commit("Add_Branch", data);
                    commit("SET_BRANCH_DTO");
                    dispatch('getNamesUniversity')
                },
                {
                    success: "تم اضافة الفرع بنجاح",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة "
                }
            );
        },
        updateBranch({ commit, dispatch }, payload) {
            api.post(
                "Department/Dash/Modify",
                payload,
                ({ data }) => {
                    commit("Update_Branch", data);
                    commit("SET_BRANCH_DTO");
                    dispatch('getNamesUniversity')

                },
                {
                    confirm: "هل تريد بالتأكيد تعديل معلومات الفرع",
                    success: "تم التعديل بنجاح  ",
                    error: "فشل التعديل.. يرجى إعادة المحاولة"
                }
            );
        },
        deleteDepartments({ commit }, ids) {
            api.delete(
                `Department/Dash/Delete`,
                () => {
                    commit("Delete_Brancheis", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الحسابات المحددة",
                    success: "تم حذف الحسابات بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة "
                },
                ids
            );
        },

    },
};
