import { Admin, SuperAdmin, Teacher, DataEntry, Accountant } from "@/router";
export default [
    {
        path: "/",
        redirect: "/admin/home",
    },
    {
        path: "/admin/home",
        name: "home",
        component: () => import("@admin/home/Home.vue"),
        meta: () => ({
            pageTitle: "الرئيسية",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الرئيسية",
                    icon: "home-alt",
                },
            ],
        }),
    },
    {
        path: "/admin/university",
        name: "university",
        components: {
            default: () => import("@admin/university/University.vue"),
            actions: () =>
                import("@admin/university/components/AddUniversity.vue"),
        },
        meta: () => ({
            pageTitle: "الجامعات",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "الجامعات",
                },
            ],
        }),
    },

    {
        path: "/admin/faculty",
        name: "faculty",
        components: {
            default: () => import("@admin/faculty/Faculty.vue"),
            actions: () => import("@admin/faculty/components/AddFaculty.vue"),
        },
        meta: () => ({
            pageTitle: "الكليات",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "store-alt",
                    text: "الكليات",
                },
            ],
        }),
    },

    {
        path: "/admin/branches",
        name: "branches",
        components: {
            default: () => import("@admin/branches/Branches.vue"),
            actions: () => import("@admin/branches/components/AddBranch.vue"),
        },
        meta: () => ({
            pageTitle: "الفروع",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "store",
                    text: "الفروع",
                },
            ],
        }),
    },
    {
        path: "/admin/subjects",
        name: "subjects",
        components: {
            default: () => import("@admin/subjects/Subjects.vue"),
            actions: () =>
                import(
                    "@/app/admin/services/subjects/components/AddSubject.vue"
                ),
        },
        meta: () => ({
            pageTitle: "المواد",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "books",
                    text: "المواد",
                },
            ],
        }),
    },
    {
        path: "/admin/subjects/:id",
        name: "subjectDetails",
        props: {
            default: true,
        },
        components: {
            default: () => import("@admin/subjects/pages/SubjectDetails.vue"),
            actions: () =>
                import("@admin/subjects/components/toolBarSubject.vue"),
        },
        meta: () => ({
            pageTitle: "المواد",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "books",
                    text: "المواد",
                    to: "/admin/subjects",
                },
                {
                    text: " تفاصيل المادة ",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/courses",
        name: "courses",
        components: {
            default: () => import("@admin/courses/Courses.vue"),
            actions: () =>
                import("@/app/admin/services/courses/components/AddCourse.vue"),
        },
        meta: () => ({
            pageTitle: "الكورس",
            roles: [SuperAdmin, Admin, DataEntry, Teacher],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "books",
                    text: "الكورس",
                },
            ],
        }),
    },
    {
        path: "/admin/courses/:id",
        name: "courseDetails",
        props: {
            default: true,
        },
        components: {
            default: () => import("@admin/courses/pages/CourseDetails.vue"),
            actions: () =>
                import("@admin/courses/components/toolBarCourse.vue"),
        },
        meta: () => ({
            pageTitle: "الكورس",
            roles: [SuperAdmin, Admin, DataEntry, Teacher],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "books",
                    text: "الكورس",
                    to: "/admin/courses",
                },
                {
                    text: " تفاصيل الكورس ",
                    active: true,
                },
            ],
        }),
    },

    {
        path: "/admin/lessons",
        name: "lessons",
        components: {
            default: () => import("@/app/admin/services/lessons/Lessons.vue"),
            actions: () => import("@admin/lessons/components/AddLesson.vue"),
        },
        meta: () => ({
            pageTitle: "الدروس",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "file-question-alt",
                    text: "الدروس",
                },
            ],
        }),
    },
    {
        path: "/admin/lessons/:id",
        props: {
            default: true,
        },
        name: "lessonDetails",
        components: {
            default: () =>
                import("@/app/admin/services/lessons/pages/LessonDetails.vue"),
            actions: () =>
                import("@admin/lessons/components/toolBarLessons.vue"),
        },

        meta: () => ({
            pageTitle: "تفاصيل درس",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "file-question-alt",
                    text: "الدروس",
                    to: "/admin/lessons",
                },
                {
                    text: "تفاصيل الدرس",
                    active: true,
                },
            ],
        }),
    },

    {
        path: "/admin/questionBank",
        name: "questionBank",
        components: {
            default: () =>
                import("@/app/admin/services/questionBank/QuestionBank.vue"),
            actions: () =>
                import("@admin/questionBank/components/AddQuestion.vue"),
        },
        meta: () => ({
            pageTitle: "بنك الاسئلة",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "question-circle",
                    text: "بنك الاسئلة",
                },
            ],
        }),
    },

    {
        path: "/admin/questionBank/:id",
        name: "questionBankDetails",
        props: {
            default: true,
        },
        components: {
            default: () =>
                import("@admin/questionBank/pages/QuestionDetails.vue"),
            // actions: () => import("@admin/questionBank/components/toolBarQuestion.vue"),
        },
        meta: () => ({
            pageTitle: " بنك الاسئلة ",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "question-circle",
                    text: "  بنك الاسئلة ",
                    to: "/admin/questionBank",
                },
                {
                    text: "تفاصيل السؤال",
                    active: true,
                },
            ],
        }),
    },

    {
        path: "/admin/codes",
        name: "codes",
        components: {
            default: () => import("@admin/codes/Package.vue"),
            actions: () => import("@admin/codes/components/AddPackage.vue"),
        },
        meta: () => ({
            pageTitle: "رموز التفعيل",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "qrcode-scan",
                    text: "رموز التفعيل",
                },
            ],
        }),
    },
    {
        path: "/admin/codes/:id",
        name: "codesDetails",
        props: {
            default: true,
        },
        components: {
            default: () => import("@admin/codes/pages/PackageDetails.vue"),
            actions: () => import("@admin/codes/components/toolBarPackage.vue"),
        },
        meta: () => ({
            pageTitle: " رموز التفعيل",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "qrcode-scan",
                    text: " الحزم المتوفرة ",
                    to: "/admin/codes",
                },
                {
                    text: "تفاصيل الحزمة",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/invoiceSellPoint",
        name: "invoiceSellPoints",
        component: () => import("@admin/invoiceSellPoint/SellPoint.vue"),
        meta: () => ({
            pageTitle: "كشف حساب ",
            roles: [SuperAdmin, Admin, Accountant],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "money-withdraw",
                    text: " كشف حساب نقاط البيع",
                },
            ],
        }),
    },
    {
        path: "/admin/invoiceSellPoint/:id",
        name: "invoiceSellPoint",
        props: {
            default: true,
            actions: true,
        },
        components: {
            default: () =>
                import("@admin/invoiceSellPoint/pages/SellPointDetails.vue"),
            actions: () =>
                import("@admin/invoiceSellPoint/pages/AddInvoiceSellPoint.vue"),
        },
        meta: (route) => ({
            pageTitle: " كشف الحساب",
            roles: [SuperAdmin, Admin, Accountant],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "money-withdraw",
                    text: " كشف حساب نقاط البيع",
                    to: "/admin/invoiceSellPoint",
                },
                {
                    text: route.query ? `فواتير ${route.query.name}` : "",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/invoiceSellPoint/:id/:invoicid",
        name: "invoiceSellPointDetails",
        props: true,

        component: () =>
            import(
                "@admin/invoiceSellPoint/components/AddSellPointInvoice.vue"
            ),
        meta: (route) => ({
            pageTitle: "كشف حساب",
            roles: [SuperAdmin, Admin, Accountant],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "money-withdraw",
                    text: " كشف حساب نقاط البيع",
                    to: "/admin/invoiceSellPoint",
                },
                {
                    text:
                        route.params.invoicid && route.params.invoicid == 0
                            ? "فاتورة جديدة"
                            : "تفاصيل الفاتورة",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/students",
        name: "students",
        components: {
            default: () => import("@admin/students/Students.vue"),
            actions: () => import("@admin/students/components/AddStudent.vue"),
        },
        meta: () => ({
            pageTitle: "مستخدم التطبيق",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "users-alt",
                    text: "مستخدم التطبيق",
                },
            ],
        }),
    },
    {
        path: "/admin/students/:id",
        name: "studentsDetails",
        props: {
            default: true,
        },
        components: {
            default: () => import("@admin/students/pages/StudentDetails.vue"),
            actions: () =>
                import("@admin/students/components/toolBarStudent.vue"),
        },
        meta: (route) => ({
            pageTitle: "مستخدم التطبيق",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "users-alt",
                    text: "مستخدم التطبيق",
                    to: "/admin/students",
                },
                {
                    text: route.query ? `تفاصيل ${route.query.name}` : "",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/dashboardUsers",
        name: "dashboardUsers",
        components: {
            default: () => import("@admin/dashboardUsers/DashboardUsers.vue"),
            actions: () =>
                import("@admin/dashboardUsers/components/AddDashboardUser.vue"),
        },
        meta: () => ({
            pageTitle: "مستخدمو اللوحة",
            roles: [SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "user-circle",
                    text: "مستخدمو اللوحة",
                },
            ],
        }),
    },
    {
        path: "/admin/sellPoints",
        name: "sellPoints",
        components: {
            default: () => import("@admin/sellPoints/SellPoints.vue"),
            actions: () =>
                import("@admin/sellPoints/components/AddSellPoints.vue"),
        },
        meta: () => ({
            pageTitle: "نقاط البيع",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "نقاط البيع",
                },
            ],
        }),
    },
    {
        path: "/admin/sellPoints/:id",
        name: "sellPointsDetail",
        props: {
            default: true,
        },
        components: {
            default: () =>
                import("@admin/sellPoints/pages/SellPointDetail.vue"),
            actions: () =>
                import("@admin/sellPoints/components/toolBarSellpoint.vue"),
        },
        meta: () => ({
            pageTitle: "نقاط البيع",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "نقطة البيع",
                    to: "/admin/sellpoints",
                },
                {
                    text: "تفاصيل",
                    active: true,
                },
            ],
        }),
    },

    {
        path: "/admin/settings",
        name: "settings",
        components: {
            default: () => import("@admin/settings/Settings.vue"),
        },
        meta: () => ({
            pageTitle: "الإعدادات",
            roles: [SuperAdmin, Admin],
            layout: "vertical",

            breadcrumb: [
                {
                    icon: "bookmark",
                    text: "الإعدادات",
                },
            ],
        }),
    },
    {
        path: "/admin/notifications",
        name: "notifications",
        components: {
            default: () => import("@admin/notifications/Notifications.vue"),
            actions: () =>
                import("@admin/notifications/components/AddNotification.vue"),
        },
        meta: () => ({
            pageTitle: "الإشعارات",
            roles: [SuperAdmin, Admin],
            layout: "vertical",

            breadcrumb: [
                {
                    icon: "bell",
                    text: "الإشعارات",
                    active: false,
                },
            ],
        }),
    },

    {
        path: "/admin/contactUs",
        name: "contactUs",
        component: () => import("@admin/contactUs/ContactUs.vue"),
        meta: () => ({
            pageTitle: "بريد الشركة",
            roles: [SuperAdmin, Admin],
            layout: "vertical",

            breadcrumb: [
                {
                    icon: "envelope-download",
                    text: "بريد الشركة",
                    active: false,
                },
            ],
        }),
    },
    {
        path: "/admin/contactUs/:id",
        name: "contactUsDetails",
        props: true,
        component: () => import("@admin/contactUs/pages/ContactUsDetails.vue"),
        meta: () => ({
            pageTitle: "بريد الشركة",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "envelope-download",
                    text: "بريد الشركة",
                    to: "/admin/contactUs",
                    active: false,
                },
                {
                    text: "  تفاصيل البريد",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/adverts",
        name: "adverts",
        components: {
            default: () => import("@admin/adverts/Adverts.vue"),
            actions: () => import("@admin/adverts/components/AddAdvert.vue"),
        },
        meta: () => ({
            pageTitle: " الإعلانات",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "megaphone",
                    text: " الإعلانات",
                    active: false,
                },
            ],
        }),
    },
];
