import api from "@api";
import router from "@/router";
import { getSearchResultOnRow } from "@Ekcore/util";

export default {
    state: {
        questionList: [],
        questionDto: {
            id: "",
            lessonId: "",
            qusetion: "",
            hint: "",
            answers: [
                {
                    isCorrect: false,
                    answer: "",
                },
            ],
        },
        columns: [
            {
                label: " رقم السؤال ",
                type: "number",
                field: "order",
            },
            {
                label: " السؤال ",
                field: "question",
            },
            {
                label: " ترتيب الكورس  ",
                type: "number",
                field: "lessonOrder",
            },
            {
                label: " اسم الكورس ",
                sortable: true,
                field: "courseId",
            },
            {
                label: "تاريخ الإضافة",
                sortable: true,
                field: "dateCreated",
            },
            {
                label: "التفاصيل",
                field: "details",
                sortable: false,
            },
        ],
    },
    getters: {
        questionsBank({ questionList }, getters, { filter }) {
            return questionList.filter(el => {
                return getSearchResultOnRow(filter, el);
            });
        }
    },
    mutations: {
        Get_Question_List(state, payload) {
            state.questionList = payload.questions;
        },
        Get_Question_DETEAILS(state, payload) {
            state.questionDto = { ...payload };
        },
        Delete_Question(state, id) {
            state.questionList.splice(
                state.questionList.findIndex((item) => item.id == id),
                1
            );
        },
        RESET_QUESTION_DETAILS(state) {
            state.questionDto = {
                id: "",
                lessonId: "",
                qusetion: "",
                hint: "",
                answers: [
                    {
                        isCorrect: false,
                        answer: "",
                    },
                ],
            };
        },
        Fill_Select_Question(state, payload) {
            state.questionDto.lessonId = payload.id;
        },
    },
    actions: {
        getQuestionList({ commit }) {
            api.get("QuestionBank/Dash/GetAll", ({ data }) => {
                commit("Get_Question_List", data);
            });
        },

        getQuestionDetails(__, id) {
            return new Promise((resolve) => {
                api.get(`QuestionBank/Dash/GetById?id=${id}`, ({ data }) => {
                    resolve(data);
                });
            });
        },

        addQuestion(__, payload) {
            return new Promise((resolve) => {
                api.post(
                    "QuestionBank/Dash/Add",
                    payload,
                    ({ data }) => {
                        resolve(data), router.push(router.go(-1));
                    },
                    {
                        success: "تم إضافة السؤال بنجاح",
                        error: "فشل إضافة السؤال",
                    }
                );
            });
        },

        updateQuestion(__, payload) {
            return new Promise((resolve) => {
                api.post(
                    "QuestionBank/Dash/Modify",
                    payload,
                    ({ data }) => {
                        resolve(data), router.push(router.go(-1));
                    },
                    {
                        success: "تم تعديل السؤال بنجاح",
                        error: "فشل التعديل ..يرجى اعادة المحاولة ",
                    }
                );
            });
        },

        deleteQuestion({ commit }, ids) {
            api.delete(
                `QuestionBank/Dash/Delete`,
                () => {
                    commit("Delete_Question", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الاسئلة المحددة",
                    success: "تم حذف الاسئلة بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
