import api from "@api";
import { getSearchResultOnRow } from "@Ekcore/util";

export default {
    state: {
        facultyName: [],
        facultyList: [],
        isDialogOpen: false,
        facultyDto: {
            name: "",
            universityId: "",
            yearCount: 0
        },
        columns: [
            {
                label: " اسم الكلية ",
                field: "name"
            },
            {
                label: "  تابعة ل ",
                field: "universityId"
            },
            {
                label: "  عدد الفروع ",
                type: "number",
                field: "departmentsCount"
            },
            {
                label: "عدد الطلاب المسجلين ",
                type: "number",
                field: "studentsCount"
            },
            {
                label: "تاريخ الإضافة",
                sortable: true,
                field: "dateCreated"
            },
            {
                label: "التفاصيل",
                field: "details",
                sortable: false
            }
        ],
    },
    getters: {
        faculties({ facultyList }, getter, { filter }) {
            return facultyList.filter(el => {
                return getSearchResultOnRow(filter, el);
            });
        },
        facultiesList: (state) => state.facultyName
    },
    mutations: {
        Get_Faculty_List(state, payload) {
            state.facultyList = payload;
        },
        Add_Faculty(state, payload) {
            state.facultyList.unshift(payload);
        },
        Update_Faculty(state, payload) {
            let dto = state.facultyList.find(ele => ele.id == payload.id);
            Object.assign(dto, payload);
        },
        Set_Faculty_Name(state, payload) {
            console.log('test',payload )
            state.facultyName = payload
            console.log('follow',state.facultyName )
        },
        IS_DIALOG_OPEN(state, payload) {
            state.isDialogOpen = payload;
        },
        SET_FACULTY_DTO(state, payload) {
            if (payload) state.facultyDto = { ...payload };
            else
                state.facultyDto = {
                    name: "",
                    universityId: "",
                    yearCount: 0
                };
        },
        Delete_Faculties(state, payload) {
            state.facultyList = state.facultyList.filter(
                el => !payload.includes(el.id)
            );
        },
    },
    actions: {
        getFacultyList({ commit }) {
            api.get("Faculty/Dash/GetAll", ({ data }) => {
                commit("Get_Faculty_List", data);
            });
        },
        addFaculty({ commit, dispatch }, payload) {
            api.post(
                "Faculty/Dash/Add",
                payload,
                ({ data }) => {
                    commit("Add_Faculty", data);
                    commit("SET_FACULTY_DTO");
                    dispatch('getNamesUniversity')

                },
                {
                    success: "تم اضافة الكلية بنجاح",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة "
                }
            );
        },
        updateFaculty({ commit, dispatch }, payload) {
            api.post(
                "Faculty/Dash/Modify",
                payload,
                ({ data }) => {
                    commit("Update_Faculty", data);
                    commit("SET_FACULTY_DTO");
                    dispatch('getNamesUniversity')

                },
                {
                    confirm: "هل تريد بالتأكيد تعديل معلومات الكلية؟",
                    success: "تم التعديل بنجاح  ",
                    error: "فشل التعديل.. يرجى إعادة المحاولة"
                }
            );
        },
        getFacultyName({ commit }) {
            api.get("Faculty/Dash/GetNames", ({ data }) => {
                commit("Set_Faculty_Name", data);
            });
        },
        deleteFaculties({ commit }, ids) {
            api.delete(
                `Faculty/Dash/Delete`,
                () => {
                    commit("Delete_Faculties", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الكليات المحددة",
                    success: "تم حذف الحسابات بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة "
                },
                ids
            );
        },

    },
};
